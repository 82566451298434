import React from "react"
import MarcasCard from "./MarcasCard"
import { useStaticQuery, graphql } from "gatsby"
import _ from "lodash"
const MarcasContainer = () => {
    const data = useStaticQuery(graphql`
        {
            marcas: allFile(filter: { sourceInstanceName: { eq: "marcas" } }) {
                edges {
                    node {
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                        name
                    }
                }
            }
            logos: allFile(
                filter: { sourceInstanceName: { eq: "marcas_logos" } }
            ) {
                edges {
                    node {
                        childImageSharp {
                            fluid(quality: 100, maxWidth: 250) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                        name
                    }
                }
            }
        }
    `)

    const marcas = []
    data.marcas.edges.map(marca => {
        const object = {}
        object.number = parseInt(marca.node.name)
        object.imagen_marca = marca.node.childImageSharp.fluid
        data.logos.edges.map(logo => {
            const logo_number = parseInt(logo.node.name)
            if (parseInt(marca.node.name) === logo_number) {
                object.logo = logo.node.childImageSharp.fluid
            }
        })
        marcas.push(object)
    })

    const marcasOrdenadas = _.sortBy(marcas, ["number"])
    return (
        <div className="marcas-cards-container">
            {marcasOrdenadas.map((marca, i) => (
                <MarcasCard
                    image={marca.imagen_marca}
                    logo={marca.logo}
                    key={i}
                />
            ))}
        </div>
    )
}

export default MarcasContainer
