import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Clientes = () => {
    const data = useStaticQuery(graphql`
        {
            cliente_1: file(relativePath: { eq: "clientes_1.png" }) {
                childImageSharp {
                    fluid(quality: 100, maxWidth: 307) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            cliente_2: file(relativePath: { eq: "clientes_2.png" }) {
                childImageSharp {
                    fluid(quality: 100, maxWidth: 315) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            cliente_3: file(relativePath: { eq: "clientes_3.png" }) {
                childImageSharp {
                    fluid(quality: 100, maxWidth: 315) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            cliente_4: file(relativePath: { eq: "clientes_4.png" }) {
                childImageSharp {
                    fluid(quality: 100, maxWidth: 209) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            cliente_5: file(relativePath: { eq: "clientes_5.png" }) {
                childImageSharp {
                    fluid(quality: 100, maxWidth: 211) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            cliente_6: file(relativePath: { eq: "clientes_6.png" }) {
                childImageSharp {
                    fluid(quality: 100, maxWidth: 187) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            cliente_7: file(relativePath: { eq: "clientes_7.png" }) {
                childImageSharp {
                    fluid(quality: 100, maxWidth: 198) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            cliente_8: file(relativePath: { eq: "clientes_8.png" }) {
                childImageSharp {
                    fluid(quality: 100, maxWidth: 300) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            cliente_9: file(relativePath: { eq: "clientes_9.png" }) {
                childImageSharp {
                    fluid(quality: 100, maxWidth: 177) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            cliente_10: file(relativePath: { eq: "clientes_10.png" }) {
                childImageSharp {
                    fluid(quality: 100, maxWidth: 300) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)

    return (
        <div
            className="clientes-section section-spacing"
            id="nutraline-clientes"
        >
            <div className="container">
                <div className="columns">
                    <div className="column">
                        <div className="clientes-title">
                            <p>
                                Nuestros <b>Clientes</b>
                            </p>
                            <div className="separador"></div>
                        </div>
                        <div className="clientes-cards-container">
                            <div className="clientes-card">
                                <Img
                                    fluid={data.cliente_1.childImageSharp.fluid}
                                    style={{ maxWidth: "307px" }}
                                />
                            </div>
                            <div className="clientes-card">
                                <Img
                                    fluid={data.cliente_2.childImageSharp.fluid}
                                    style={{ maxWidth: "315px" }}
                                />
                            </div>
                            <div className="clientes-card">
                                <Img
                                    fluid={data.cliente_3.childImageSharp.fluid}
                                    style={{ maxWidth: "315px" }}
                                />
                            </div>
                            <div className="clientes-card">
                                <Img
                                    fluid={data.cliente_4.childImageSharp.fluid}
                                    style={{ maxWidth: "209px" }}
                                />
                            </div>
                            <div className="clientes-card">
                                <Img
                                    fluid={data.cliente_5.childImageSharp.fluid}
                                    style={{ maxWidth: "211px" }}
                                />
                            </div>
                            <div className="clientes-card">
                                <Img
                                    fluid={data.cliente_6.childImageSharp.fluid}
                                    style={{ maxWidth: "187px" }}
                                />
                            </div>
                            <div className="clientes-card">
                                <Img
                                    fluid={data.cliente_7.childImageSharp.fluid}
                                    style={{ maxWidth: "198px" }}
                                />
                            </div>
                            <div className="clientes-card">
                                <Img
                                    fluid={data.cliente_8.childImageSharp.fluid}
                                    style={{ maxWidth: "307px" }}
                                />
                            </div>
                            <div className="clientes-card">
                                <Img
                                    fluid={data.cliente_9.childImageSharp.fluid}
                                    style={{ maxWidth: "177px" }}
                                />
                            </div>
                            <div className="clientes-card">
                                <Img
                                    fluid={
                                        data.cliente_10.childImageSharp.fluid
                                    }
                                    style={{ maxWidth: "300px" }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Clientes
