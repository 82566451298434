import React from "react"
import BackgroundImage from "gatsby-background-image"
import { useStaticQuery, graphql } from "gatsby"

const QuienesSomos = () => {
    const data = useStaticQuery(graphql`
        {
            file(relativePath: { eq: "background-top.jpg" }) {
                childImageSharp {
                    fluid(quality: 100, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)
    return (
        <BackgroundImage
            Tag="section"
            fluid={data.file.childImageSharp.fluid}
            backgroundColor={`#040e18`}
            className="quien-background"
            id="nutraline-quienessomos"
        >
            <div className="quien-section-text">
                <div className="quien-text-container">
                    <div className="quien-text-title">
                        EXPERTOS EN ENTREGAS <br /> DE SOLUCIONES COMPLETAS
                    </div>
                    <div className="quien-text-subtitle">
                        para el desarrollo de la categoría de suplementos
                        nutricionales <br /> en Wellness, Sport Nutrition y
                        Dieta
                    </div>
                </div>
            </div>
        </BackgroundImage>
    )
}

export default QuienesSomos
