import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import TelefonoIcon from "../assets/telefono.svg"
import EmailIcon from "../assets/email.svg"
import ClockIcon from "../assets/clock.svg"
const Footer = () => {
    const data = useStaticQuery(graphql`
        {
            map: file(relativePath: { eq: "map.jpg" }) {
                childImageSharp {
                    fluid(quality: 100, maxWidth: 562) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            logoFt: file(relativePath: { eq: "logoFt.png" }) {
                childImageSharp {
                    fluid(quality: 100, maxWidth: 154) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)
    return (
        <footer className="footer-section section-spacing">
            <div className="container">
                <div className="columns">
                    <div className="column is-half">
                        <div className="footer-credits">
                            <div className="footer-logo">
                                <b>UNA EMPRESA</b>
                                <Img
                                    fluid={data.logoFt.childImageSharp.fluid}
                                    style={{ maxWidth: "154px" }}
                                />
                            </div>
                            <div className="footer-number">
                                <TelefonoIcon /> +56 9 8818 81 25
                            </div>
                            <div className="footer-email">
                                <EmailIcon /> contacto@nutraline.cl
                            </div>
                            <div className="footer-clock">
                                <ClockIcon /> Horario de atención: Lunes a Viernes de 9:00 a 18:00 hrs
                            </div>

                            <div className="footer-credits-text">
                                © 2020 NUTRALINE LTDA | ALL RIGHTS RESERVED
                            </div>
                        </div>
                    </div>
                    <div className="column is-half">
                        <div className="footer-ubication">
                            <Img fluid={data.map.childImageSharp.fluid} />
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
