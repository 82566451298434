import React, { useState } from "react"
import ImportacionesIcon from "../assets/importaciones.svg"
import InvestigacionIcon from "../assets/investigacion.svg"
import AsuntosIcon from "../assets/asuntos.svg"
import GestionIcon from "../assets/gestion.svg"
import VentasIcon from "../assets/ventas.svg"
const Areas = () => {
    const [area, setArea] = useState("importaciones")

    return (
        <section className="areas-section section-spacing" id="nutraline-areas">
            <div className="container">
                <div className="columns">
                    <div className="column">
                        <div className="areas-title">
                            Areas de trabajo
                            <div className="separador"></div>
                        </div>

                        <div className="areas-tabs">
                            <div
                                className={`areas-tab ${area ===
                                    "importaciones" && "areas-tab-active"}`}
                                onClick={() => setArea("importaciones")}
                            >
                                <ImportacionesIcon id="Importaciones" />
                                <div className="areas-tab-title">
                                    IMPORTACIONES
                                </div>
                            </div>

                            <div
                                className={`areas-tab ${area ===
                                    "investigacion" && "areas-tab-active"}`}
                                onClick={() => setArea("investigacion")}
                            >
                                <InvestigacionIcon id="Investigacion" />
                                <div className="areas-tab-title">
                                    INVESTIGACION <br /> Y DESARROLLO
                                </div>
                            </div>
                            <div
                                className={`areas-tab ${area === "asuntos" &&
                                    "areas-tab-active"}`}
                                onClick={() => setArea("asuntos")}
                            >
                                <AsuntosIcon id="Asuntos" />
                                <div className="areas-tab-title">
                                    ASUNTOS <br /> REGULATORIOS
                                </div>
                            </div>
                            <div
                                className={`areas-tab ${area === "gestion" &&
                                    "areas-tab-active"}`}
                                onClick={() => setArea("gestion")}
                            >
                                <GestionIcon id="Gestion" />
                                <div className="areas-tab-title">
                                    GESTION DE <br /> MARKETING
                                </div>
                            </div>
                            <div
                                className={`areas-tab ${area === "ventas" &&
                                    "areas-tab-active"}`}
                                onClick={() => setArea("ventas")}
                            >
                                <VentasIcon id="Ventas" />
                                <div className="areas-tab-title">VENTAS</div>
                            </div>
                        </div>
                        <div className="areas-description">
                            <div className="areas-description-fixed">
                                Los mejores socios <br /> comerciales con un
                                {"  "}
                                <br />
                                servicio integral de <br />
                                apoyo
                            </div>

                            <div className="areas-description-dynamic">
                                {area === "importaciones" &&
                                    "Nutraline se ha especializado en todos los procesos para llevar a cabo una importación eficiente dado que sabemos que contar con el producto indicado en el momento indicado es uno de los factores de éxito para nuestros clientes. Esto hace que hoy contemos con los mejores aliados a nivel mundial y podamos estar trabajando de forma eficiente desde Europa, Oceanía, India y America del Norte. Contamos además con bodegas en Miami de manera de optimizar procesos y llegar a las mejores economías de escala para alcanzar los mejores costos para nosotros y nuestros clientes."}
                                {area === "investigacion" &&
                                    "Nutraline sabe que unas de las ventajas competitivas mas importantes hoy en día es tener la capacidad de estar a la vanguardia en suplementación alimenticia. Esto incluye el estar continuamente informados de las tendencias mundiales, entender cada nicho de consumidor en forma profunda y tener claro todo lo referente a temas regulatarios locales de manera de que todos estos factores confluyan en un producto innovador, efectivo, legal y vanguardista de manera de garantizar el éxito de cada proyecto."}
                                {area === "asuntos" &&
                                    "Contamos actualmente con el mejor de los equipos en áreas regulatorias de manera de entregar un servicio que le permita al cliente preocuparse del core de su negocio y tener la tranquilidad que tiene resuelto todo lo que tenga que ver con las autorizaciones necesarias de este mercado."}
                                {area === "gestion" &&
                                    "Nutraline sabe la importancia de comunicar de forma correcta al consumidor. Por este motivo es que contamos con todo el departamento de marketing “inside” ya que creemos que la pasión por la perfección se logra únicamente si logramos vivir desde adentro la nutrición, el deporte, el buen vivir y el gozar la vida."}
                                {area === "ventas" &&
                                    "En Nutraline sabemos que muchos pueden comprar y vender pero también sabemos que pocos pueden transformarse en un partner o socios con sus clientes. Por este motivo es que nos preocupamos de que los clientes tengan todo lo que pueda ser necesario para que desarrollen sus negocios y se sientan acompañados en esto. Sabemos que esta promesa es una ardua tarea por la cantidad de procesos y actores que están involucrados pero esto es lo que nos mueve día a día."}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Areas
