import React from "react"

import Fade from "react-reveal/Fade"
import { Link as LinkScroll } from "react-scroll"
const Sidebar = ({ setSidebarOpen, sidebarOpen }) => {
    return (
        <Fade left when={sidebarOpen}>
            <aside
                className={`sidebar-menu ${sidebarOpen && "sidebar-visible"}`}
            >
                <ul className="sidebar-menu-items">
                    <LinkScroll
                        className="sidebar-menu-item"
                        to="nutraline-quienessomos"
                        spy={true}
                        smooth={true}
                        offset={-92}
                        duration={500}
                        onClick={() => setSidebarOpen(!sidebarOpen)}
                    >
                        QUIENES SOMOS
                    </LinkScroll>
                    <LinkScroll
                        className="sidebar-menu-item"
                        to="nutraline-areas"
                        spy={true}
                        smooth={true}
                        offset={-92}
                        duration={500}
                        onClick={() => setSidebarOpen(!sidebarOpen)}
                    >
                        SERVICIOS
                    </LinkScroll>
                    <LinkScroll
                        className="sidebar-menu-item"
                        to="nutraline-marcas"
                        spy={true}
                        smooth={true}
                        offset={-92}
                        duration={500}
                        onClick={() => setSidebarOpen(!sidebarOpen)}
                    >
                        MARCAS
                    </LinkScroll>
                    <LinkScroll
                        className="sidebar-menu-item"
                        to="nutraline-clientes"
                        spy={true}
                        smooth={true}
                        offset={-92}
                        duration={500}
                        onClick={() => setSidebarOpen(!sidebarOpen)}
                    >
                        CLIENTES
                    </LinkScroll>
                    <a
                        className="sidebar-menu-item"
                        href="https://www.webpay.cl/portalpagodirecto/pages/institucion.jsf?idEstablecimiento=18700453"
                        onClick={() => setSidebarOpen(!sidebarOpen)}
                    >
                        PAGO EN LINEA
                    </a>
                    <LinkScroll
                        className="sidebar-menu-item"
                        to="nutraline-contacto"
                        spy={true}
                        smooth={true}
                        offset={-92}
                        duration={500}
                        onClick={() => setSidebarOpen(!sidebarOpen)}
                    >
                        CONTACTO
                    </LinkScroll>
                </ul>
            </aside>
        </Fade>
    )
}

export default Sidebar
