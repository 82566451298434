import React, { Fragment, useState, useEffect } from "react"
import Navbar from "../components/Navbar"

import "bulma/bulma.sass"
import "bulma-helpers/bulma-helpers.sass"
import "../scss/main.scss"
import Sidebar from "../components/Sidebar"
import ReactGA from "react-ga"
const Base = ({ children }) => {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    useEffect(() => {
        ReactGA.initialize("UA-93995842-1")
        ReactGA.pageview(window.location.pathname + window.location.search)
    }, [])
    return (
        <Fragment>
            <Sidebar
                setSidebarOpen={setSidebarOpen}
                sidebarOpen={sidebarOpen}
            />
            <Navbar setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen} />
            {children}
        </Fragment>
    )
}

export default Base
