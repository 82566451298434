import React, { useEffect } from "react"
import BackgroundImage from "gatsby-background-image"
import { useStaticQuery, graphql } from "gatsby"


const Contacto = () => {

    useEffect(() => {
        const script = document.createElement('script');

        script.src = 'https://nutraline.activehosted.com/f/embed.php?id=2';
        script.async = true;

        document.body.appendChild(script);
    });

    const data = useStaticQuery(graphql`
        {
            file(relativePath: { eq: "background-bottom.jpg" }) {
                childImageSharp {
                    fluid(quality: 100, maxWidth: 1920) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)
    return (
        <BackgroundImage
            Tag="section"
            fluid={data.file.childImageSharp.fluid}
            backgroundColor={`#040e18`}
            className="contacto-section section-spacing"
            id="nutraline-contacto"
        >
            <div className="container">
                <div className="columns">
                    <div className="column">
                        <div className="contacto-text-container">
                            <div className="contacto-title">
                                ¿TIENES DUDAS? <b>CONTÁCTANOS</b>
                                <div className="contacto-subtitle">
                                    Si quieres contactarte con nosotros,
                                    envíanos un mensaje a través de nuestro
                                    formulario de contacto y te responderemos a
                                    la brevedad.
                                </div>
                                <div className="separador-blanco"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="columns">
                    <div className="column is-3"></div>
                    <div className="column is-6 contacto-column">
                        <div className="_form_2" />
                    </div>
                    <div className="column is-3"></div>
                </div>
            </div>
        </BackgroundImage>
    )
}

export default Contacto
