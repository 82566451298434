import React from "react"
import MarcasContainer from "./MarcasContainer"

const Marcas = () => {
    return (
        <div className="marcas-section section-spacing" id="nutraline-marcas">
            <div className="container">
                <div className="columns">
                    <div className="column">
                        <div className="marcas-title">
                            <p>
                                Nuestras <b>Marcas</b>
                            </p>
                            <div className="separador-blanco"></div>
                        </div>
                        <div className="marcas-description">
                            Nutraline, después de muchos años de trabajo, ha
                            logrado establecerse como un referente importante en
                            el mundo del deporte y el Wellness, de esta forma
                            poseemos fuertes lazos con las más grandes e
                            importantes marcas en cada uno de los nichos que
                            actualmente desarrollamos. Esto lo consideramos sin
                            duda como un importante activo en la compañía ya que
                            son estos lazos los que nos permiten estar a la
                            vanguardia y dar un soporte y apoyo al cliente sin
                            comparación. Nutraline entiende que para construir
                            grandes marcas y grandes negocios se requieren
                            grandes aliados.
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="columns">
                    <div className="column">
                        <MarcasContainer />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Marcas
