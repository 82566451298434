import React from "react"
import Img from "gatsby-image"

const MarcasCard = ({ image, logo }) => {
    return (
        <article className="marcas-card">
            <div className="marcas-image">
                <Img fluid={image} className="marcas-card-image" />
            </div>
            <div className="marcas-logo">
                <Img fluid={logo} />
            </div>
        </article>
    )
}

export default MarcasCard
