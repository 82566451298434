import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { Link as LinkScroll } from "react-scroll"

const Navbar = ({ setSidebarOpen, sidebarOpen }) => {
    const data = useStaticQuery(graphql`
        {
            file(relativePath: { eq: "logo.png" }) {
                childImageSharp {
                    fixed(quality: 100, width: 250) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)

    const [changeNav, setChangeNav] = useState(false)
    const [scrollPosition, setScrollPosition] = useState(0)

    useEffect(() => {
        window.addEventListener("scroll", listenToScroll)
        if (scrollPosition >= 150 && !changeNav) {
            setChangeNav(true)
        }
        if (scrollPosition <= 150 && changeNav) {
            setChangeNav(false)
        }
    }, [scrollPosition])

    const listenToScroll = () => {
        const scrolled = window.scrollY
        setScrollPosition(scrolled)
    }

    return (
        <nav
            className={`navbar is-fixed-top ${changeNav && "navbar-onscroll"}`}
            role="navigation"
            aria-label="main navigation"
        >
            <div className="navbar-brand">
                <Link to="/" title="Logo">
                    <Img
                        fixed={data.file.childImageSharp.fixed}
                        className="header-logo"
                        imgStyle={{ objectFit: "contain" }}
                    />
                </Link>

                <i
                    className="navbar-burger burger"
                    onClick={() => setSidebarOpen(!sidebarOpen)}
                >
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </i>
            </div>

            <div id="navbarBasicExample" className="navbar-menu">
                <div className="navbar-start"></div>

                <div className="navbar-end">
                    <LinkScroll
                        activeClass="active"
                        className="navbar-item"
                        to="nutraline-quienessomos"
                        spy={true}
                        smooth={true}
                        offset={-92}
                        duration={500}
                    >
                        QUIENES SOMOS
                    </LinkScroll>
                    <LinkScroll
                        activeClass="active"
                        className="navbar-item"
                        to="nutraline-areas"
                        spy={true}
                        smooth={true}
                        offset={-92}
                        duration={500}
                    >
                        SERVICIOS
                    </LinkScroll>
                    <LinkScroll
                        activeClass="active"
                        className="navbar-item"
                        to="nutraline-marcas"
                        spy={true}
                        smooth={true}
                        offset={-92}
                        duration={500}
                    >
                        MARCAS
                    </LinkScroll>
                    <LinkScroll
                        activeClass="active"
                        className="navbar-item"
                        to="nutraline-clientes"
                        spy={true}
                        smooth={true}
                        offset={-92}
                        duration={500}
                    >
                        CLIENTES
                    </LinkScroll>
                    <a
                        className="navbar-item"
                        href="https://www.webpay.cl/portalpagodirecto/pages/institucion.jsf?idEstablecimiento=18700453"
                    >
                        PAGO EN LINEA
                    </a>
                    <LinkScroll
                        activeClass="active"
                        className="navbar-item"
                        to="nutraline-contacto"
                        spy={true}
                        smooth={true}
                        offset={-92}
                        duration={500}
                    >
                        CONTACTO
                    </LinkScroll>
                </div>
            </div>
        </nav>
    )
}

export default Navbar
