import React from "react"
import Layout from "../layouts/Layout"
import SEO from "../components/seo"
import QuienesSomos from "../components/QuienesSomos"
import Areas from "../components/Areas"
import Marcas from "../components/Marcas"
import Clientes from "../components/Clientes"
import Contacto from "../components/Contacto"
import Footer from "../components/Footer"

const IndexPage = () => (
    <Layout>
        <SEO title="Home" />
        <QuienesSomos />
        <Areas />
        <Marcas />
        <Clientes />
        <Contacto />
        <Footer />
    </Layout>
)

export default IndexPage
